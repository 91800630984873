import React, { Fragment } from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  padding: 32px;
  font-size: 36px;
  margin: 0;
  background-color: #f4f7fa;
  color: #3b4151;
  font-family: Open Sans,sans-serif;
`;

const Content = styled.div`
  padding: 0 20px;
  margin: 2em;
`;

const Page = ({ title, children }) => (
  <Fragment>
    <Header>{title}</Header>
    <Content>
      {children}
    </Content>
  </Fragment>
);

export default Page;
