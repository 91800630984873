import React from 'react';
import styled from 'styled-components';
import logo from '../logo.svg';
import { NavLink } from 'react-router-dom';

const Logo = styled.img`
  width: 105px;
  height: 50px;
  float: left;
`;

const Navbar = styled.div`
  height: 50px;
  padding: 0 1em;
`;

const Navlist = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
`;

const NavItem = styled.li`
  float: left;
  display: inline-block;
  padding: 1em 0.625em;
  a {
    text-decoration: none;
    cursor: pointer;
    color: grey;
    &:hover {
      text-decoration: underline;
    }
  }
  .active {
    color: black;
  }
`;

const Header = ({ items, update, active }) => (
  <Navbar>
    <Logo src={logo} />
    <Navlist>
      {items.map(item => (
        <NavItem key={item.name}>
          <NavLink exact={"true"} className={({ isActive }) => (isActive ? 'active' : '')} to={item.path}>{item.name}</NavLink>
        </NavItem>
      ))}
      <NavItem>
        <a href="https://goo.gl/forms/DiQs8Q6GxE7VxSRs1" target="_blank" rel="noopener noreferrer">
          Feedback
        </a>
      </NavItem>
    </Navlist>
  </Navbar>
);

export default Header;
