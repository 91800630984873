import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Header from './components/Header';
import Page from './components/Page';
import Markdown from './components/Markdown';
import './App.css';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

class App extends Component {
  state = {
    markdown: '',
    routes: [
      {
        name: 'Getting Started',
        path: '/',
      },
      {
        name: 'API Reference',
        path: '/reference',
      },
    ],
  };

  componentDidMount() {
     fetch('/docs/GettingStarted.md')
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { routes, markdown } = this.state;
    return (
      <Router>
        <div>
          <Header items={routes} />
          <Routes>
            {
              markdown.length > 0 &&
                <Route
                  key='/'
                  path='/'
                  element={
                    <Page title="WP Engine API">
                      <Markdown>{markdown}</Markdown>
                    </Page>
                  }
                />
            }
            <Route
              key='/reference'
              path='/reference'
              element={<SwaggerUI url="https://api.wpengineapi.com/v1/swagger" />}
            />
          </Routes>
        </div>
    </Router>
    );
  }
}

export default App;
