import React from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Markdown = ({ children }) => (
  <ReactMarkdown
    children={children}
    components={{
      code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={atomOneDark}
            language={match[1]}
            PreTag="div"
            {...props}
            />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }
    }}
  />
);

export default Markdown;
